.newsletter {
  padding-bottom:25px;
  width: 50%;
  margin: 0 auto;
  @include max_tablet {
    width: 100%;
  }
}
.newsletter-form {
    border-radius: 4px;
    position: relative;
    margin-bottom: 30px;
}
.nwl-input {
  width: calc(100% - 148px);
  height: 58px;
  border: 2px solid #fff;
  background: transparent;
  color: #fff;
  margin-right: 10px;
  border-radius: 0;
  padding-left: 20px;
  -webkit-transition: padding-left .15s ease-out;
  transition: padding-left .15s ease-out;
  float:left;
  @include max_tablet {
    width: calc(100% - 150px);
  }
}
.nwl-input:focus {
  padding-left:30px;
      outline: 0;
}
.nwl-input::placeholder {
color:$white;
font-size:14px;
}
.nwl-input:-ms-input-placeholder {
  color:$white;
}
.nwl-buton {
    width: 138px;
    height: 58px;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    -webkit-appearance: button;
    cursor: pointer;
    border: 0;
    vertical-align: middle;
    outline: 0;
    margin: 0;
    top: 0;
}
.nwl-buton.btn--color:hover {
  background: #fc0;
}
