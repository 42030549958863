/* layout */

/* colors */
$templateColor: #333;
$titleColor: #0a0a0a;
$white: #fff;
$hoverColor: #cca300;
$grey:#efefef;
/* fonts */
$defaultFont: 'Open Sans',sans-serif;
$menuFont: 'Montserrat', sans-serif;
$titleFont: 'Amatic SC', cursive;
$mapFont: 'Roboto', sans-serif;
