.iconbox-black {
  color: #555;
}
.iconbox-white {
  color: $white;
}
.ph-iconbox {
  text-align: center;
  margin-bottom: 30px;
      &__icon-wrapper {
        position: relative;
            margin-bottom: 20px;
      }
      &__icon {
            background-color: #111;
            font-size: 80px;
            padding: 100px;
            color: #fc0;
            display: inline-block;
            border-radius: 50%;
            vertical-align: middle;
            position: relative;
            z-index: 1;
            -webkit-transition: background-color .2s ease-out;
            transition: background-color .2s ease-out;
      }
      &__icon:before {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate3d(-50%,-50%,0);
        transform: translate3d(-50%,-50%,0);
        line-height: 1;
      }
      &__icon:after {
        background-color: #111;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 50%;
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
        -webkit-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        -webkit-transition-property: opacity,-webkit-transform;
        transition-property: transform,opacity;
        -webkit-transition-duration: .5s;
        transition-duration: .5s;
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
      }
      &__title {
        font-family: $titleFont;
        font-size: 36px;
        line-height: 46px;
        font-weight: 700;
        color: #0a0a0a;
        letter-spacing: 3px;
      }
      &__desc-wrapper {
        margin-bottom: 20px;
      }
      &__desc {
        font-family: $defaultFont;
        font-size: 14px;
        line-height: 30px;
        font-weight: 400;
        font-style: normal;
      }
  &--phone {
    &__icon-wrapper {
      position: relative;
      margin-bottom: 20px;
      display:inline-block;
      float:left;
      margin-right: 20px;
      margin-bottom: 15px;
    }
    &__title {
      font-family: $menuFont;
      font-weight: 700;
      color: #0a0a0a;
      font-size: 16px;
    }
  }
}
.ph-iconbox:hover {
      &__icon {
        color: #fc0;
      }
}
.ph-iconbox--phone:hover {
    a {
        color: #cd2122;
      }
}
.ph-iconbox:hover {
    .ph-iconbox__icon:after {
      -webkit-transition-timing-function: cubic-bezier(0.22,.61,.36,1);
      transition-timing-function: cubic-bezier(0.22,.61,.36,1);
      -webkit-transition-duration: .2s;
      transition-duration: .2s;
      background-color: #111;
      -webkit-transform: scale(1.15);
      transform: scale(1.15);
      opacity: 1;
    }

  }
