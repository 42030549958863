.paraxify{
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
}
.specialities {
  position:relative;
  overflow: hidden;
  height: 500px;
  @include min_ldesktop {
    height:600px;
  }
}
.delivery:after, .tasty:after {
  content:"";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(53,53,53,0.6);
}
.specialities:after {
  content:"";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(20,20,20,0.6);
}
.delivery {
  position: relative;
  overflow: hidden;
  height:500px;
  @include min_ldesktop {
    height:600px;
  }
}
.tasty {
  position:relative;
  overflow: hidden;
  height:500px;
  @include min_ldesktop {
    height:600px;
  }
}
.paral-wrapper {
  width:100%;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}
.paral_container {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  z-index: 3;
  margin: 0 auto;

}
