.totop {
    height: 9px;
    opacity: 0;
    position: fixed;
    right: 10px;
    width: 49px;
    z-index: 999;
    display: block;
    top: 85%;
    background-repeat: no-repeat;
    background-position: center 15px;
    background-color: #404040;
    font-size: 9px;
    font-weight: 900;
    color: #fff;
    text-align: center;
    line-height: 1;
    border-radius: 2px;
    padding: 10px 0 39px;
    transition: all .2s ease-out;
}
.totop:hover,.totop:visited, .totop:focus {
    opacity: 1;
    color: #fff;
}
.totop-vissible {
  opacity: 0.7;
}
.totop-text {
  margin-top:10px;
}
